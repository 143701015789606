import React, {useEffect, useState} from 'react'
import {ClockIcon,} from '@heroicons/react/24/outline'
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import axios from 'axios';

type appObject = {
    uuid: string
    description: string
}

export const Applications = ({uuid, description}: appObject) => {
    const [isLoading, setIsLoading] = useState(true)
    const [item, setItem] = useState<any>();

    const getApplication = async () => {
        const token = typeof window !== 'undefined' && localStorage.getItem('access_token');

        let element;
        // const response = await axios
        //     .get(process.env.API_URL + `/api/applications/${uuid}`)
        // element = response?.data?.course;
        // console.log(element);
        // setItem(element)

        let config = {
            method: 'get',
            url: process.env.API_URL + `/api/applications/${uuid}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        };

        axios(config)
            .then(function (response) {
                setItem(response.data.data.application.course);
            })
            .catch(function (error) {
                console.log(error);
            }).finally(() =>
            setIsLoading(false)
        );
    }

    useEffect(() => {
        getApplication();
    }, []);

    return (
        <>
            {item === undefined && isLoading ?
                <div className="rounded-xl flex shadow-lg relative mb-6 py-8 px-4">
                    <span className="font-bold mx-2">{description}</span> is loading information...
                </div> : null}

            {
                (item !== undefined) ?
                    <div className="rounded-xl bg-white flex shadow-lg relative items-center flex-col md:flex-row mb-6">
                        <div className="relative w-full md:w-[200px]">
                            <div
                                className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                            <img src={item.imgUrl} alt=""
                                 className="w-full md:w-[200px] object-cover h-[100px] lg:h-[120px] rounded-xl bg-slate-300"/>
                        </div>

                        <div className="p-[15px] md:pl-8 md:p-5 md:flex md:items-center md:justify-between w-full">
                            <div>
                                <h4 className="text-[16px] lg:text-[26px] ff-cg--semibold leading-none mb-[10px]">{item.title}</h4>
                                <div className="flex items-center lex-wrap">
                                    {/* {
                                        (item.categories.length) ?
                                            <>
                                                {
                                                    item.categories.map((item: any, index: number) => {
                                                        return (
                                                            <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                                                                <span className="ff-cg--semibold text-[12px]">{item.name}</span>
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </> : ""
                                    } */}
                                    <span
                                        className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                        <ClockIcon className="h-4 w-4 mr-[6px]"/>
                                        <span className="ff-cg--semibold text-[12px]">{item.duration}</span>
                                    </span>
                                    <span
                                        className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                        <ClockIcon className="h-4 w-4 mr-[6px]"/>
                                        <span className="ff-cg--semibold text-[12px]">{item.access}</span>
                                    </span>
                                </div>
                            </div>

                            <button
                                className="w-full lg:w-fit flex flex-col items-center justify-between border solid border-black py-3 px-[16px] rounded-2xl md:ml-[20px] mt-4 md:mt-0 relative">
                                <Tippy content={<>
                                    <h3 className="text-center font-bold mb-2 pt-1 text-sm text-gray-900">We're
                                        configurin the access to your courses</h3>
                                    <p className="text-center pb-2 text-xs text-gray-900">We are currently working to
                                        have everything ready for you. You will start your learning path soon.</p>
                                </>}>
                                    <span
                                        className="absolute text-xs top-0 right-0 bg-yellow-500 border-2 border-gray-800rounded-full w-4 h-4 flex justify-center items-center">i</span>
                                </Tippy>
                                <span className="leading-none text-[12px]">Status</span>
                                <span className="ff-cg--semibold text-[12px] leading-none">Pending</span>
                            </button>
                        </div>
                    </div>
                    : ""
            }
        </>
    )
}
