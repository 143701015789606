import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import {useFormik} from 'formik';
import {MuiTelInput} from 'mui-tel-input';
import {navigate} from "gatsby";
import 'tippy.js/dist/tippy.css';
import {Footer} from "../components/Footer/Footer";
import {useAppDispatch} from "../store/hooks";
import {Applications} from "../components/Applications.tsx/Applications";
import LoaderIcon from '../images/loader.svg';
import StrongPassword from "../components/StrongPassword/StrongPassword";
import {Loader} from "../components/Loader/Loader";
import {useUser} from "gatsby-plugin-clerk";
import toast from "react-hot-toast";
import {getApplicationsDetailed} from "../helpers/courses";
import {useSelector} from "react-redux";
import {RootState} from "../reducers/rootReducer";
import {fetchLogin, updateUser} from "../reducers/loginSlice";
import {fetchUser} from "../reducers/userSlice";

const Account = ({location}: any) => {
    const {isSignedIn, user: userDataClerk} = useUser();

    const {items}: any = useSelector((state: RootState) => state.loginSlice);

    const userName = typeof window !== 'undefined' && localStorage.getItem('name');
    const user = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user') || '{}');
    const [userData, setUserData] = useState<any>([]);
    const [signed, setSigned] = useState(false);
    const [edit, setEdit] = useState(true);
    const [change, setChange] = useState(false);
    const [application, setApplication] = useState(false);
    const [apps, setApps] = useState(false);
    const [validPassword, setValidPassword] = useState<boolean>(false);
    const [notification, setNotification] = useState(false);
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [applications, setApplications] = useState<any>([]);
    const [userId, setUserId] = useState("");
    const [loading, setLoading] = useState(true);

    const dispatch = useAppDispatch();

    const formikEdit = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: items.user?.firstName ?? "",
            lastName: items.user?.lastName ?? "",
            email: items.user?.email ?? "",
            mobilePhone: items.user?.phone ?? ""
        },
        validate: (values) => {
            const errors: { firstName?: string; lastName?: string; email?: string; mobilePhone?: string; } = {};

            if (!values.firstName) {
                errors.firstName = 'Required';
            }

            if (!values.lastName) {
                errors.lastName = 'Required';
            }

            if (!values.email) {
                errors.email = 'Required';
            }

            if (values.email && values.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email))) {
                errors.email = 'Required';
            }

            if (!values.mobilePhone) {
                //errors.mobilePhone = 'Required';
            }

            return errors;
        },
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                setSubmitLoader(true);

                userDataClerk?.update({
                    firstName: values.firstName,
                    lastName: values.lastName,
                })

                if (!userDataClerk?.id) {
                    throw new Error('User not found');
                }

                await updateUser(items.user.uuid, values)

                await dispatch(fetchLogin(userDataClerk.id));

                await dispatch(fetchUser(userDataClerk.id));

                toast.success("This was successful")
            } catch (event) {
                const error = event as Error;
                console.error(error);
                toast.error(error?.message ?? "")
            }
            setSubmitLoader(false);
        }
    });


    const formikChange = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newPassword: ''
        },
        validate: (values) => {
            const errors: { oldPassword?: string; newPassword?: string; } = {};

            if (!values.oldPassword) {
                errors.oldPassword = 'Required';
            }

            if (!values.newPassword || !validPassword) {
                errors.newPassword = 'Required';
            }

            return errors;
        },
        validateOnChange: false,
        onSubmit: async (values) => {
            setSubmitLoader(true);
            await changePassword(values);
            setSubmitLoader(false)
        }
    });

    useEffect(() => {
        resApplications();
    }, []);

    const changePassword = async (data: any) => {
        if (!userDataClerk) return;

        userDataClerk.updatePassword({
            currentPassword: data.oldPassword,
            newPassword: data.newPassword,
        }).then((response: any) => {
            console.log(response);
            console.log("Password updated successfully");
            toast.success("This was successful");
        }).catch((error: any) => {
            console.log("->", error.errors[0].longMessage)
            console.log("An error occurred:", error.errors);
            console.log("error", error);
        });
    }

    const handlePassword = (data: boolean) => {
        setValidPassword(data);
    }

    const resApplications = async () => {
        const appsToShow = await getApplicationsDetailed();
        setApplications(appsToShow);
        setLoading(false);
    }

    const handleChange = (status: string) => {
        if (status === 'edit') {
            setEdit(true);
            setChange(false);
            setApplication(false);
        } else if (status === 'change') {
            setEdit(false);
            setChange(true);
            setApplication(false);
        } else if (status === 'application') {
            setEdit(false);
            setChange(false);
            setApplication(true);
        } else {
            setEdit(true);
            setChange(false);
            setApplication(false);
        }
    };

    return (
        <Layout signed={signed}>
            <div className="bg-slate-50">
                {/* <Header isSignIn={signed} /> */}
                {/* Title tab */}
                <section className="container px-[15px] mx-auto">
                    <div className="mt-10  mb-10 flex lg:items-center justify-between flex-col lg:flex-row">
                        <h3 className="text-[20px] lg:text-[40px] mb-6 lg:mb-0">My <span
                            className="ff-cg--semibold">Account</span></h3>
                        <div className="flex items-center overflow-x-auto">
                            <div className="flex items-center flex-col cursor-pointer"
                                 onClick={() => handleChange('edit')}>
                                <p className={`pb-3 px-10 whitespace-nowrap ${edit ? "ff-cg--semibold " : ""}`}>Edit
                                    Profile</p>
                                <span
                                    className={`border-b border-solid w-full ${edit ? "border-[#da1a32] border-2" : ""}`}></span>
                            </div>
                            <div className="flex items-center flex-col cursor-pointer"
                                 onClick={() => handleChange('change')}>
                                <p className={`pb-3 px-10 whitespace-nowrap ${change ? "ff-cg--semibold " : ""}`}>Change
                                    Password</p>
                                <span
                                    className={`border-b border-solid w-full ${change ? "border-[#da1a32] border-2" : ""}`}></span>
                            </div>
                            <div className="flex items-center flex-col cursor-pointer"
                                 onClick={() => handleChange('application')}>
                                <p className={`pb-3 px-10 whitespace-nowrap ${application ? "ff-cg--semibold " : ""}`}>Manage
                                    Applications</p>
                                <span
                                    className={`border-b border-solid w-full ${application ? "border-[#da1a32] border-2" : ""}`}></span>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Edit Profile */}
                {(edit) && (
                    <section className="container px-[15px] mx-auto md:mb-20 mb-10">
                        <form onSubmit={formikEdit.handleSubmit}
                              className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                            <h3 className="text-[20px] md:text-[30px] mb-6">Edit Profile</h3>
                            <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mb-10">
                                <div className="md:col-span-12 lg:col-span-6">
                                    <div className="flex items-center justify-between">
                                        <p className="ff-cg--semibold">First Name</p>
                                    </div>
                                    <input
                                        type="text"
                                        name="firstName"
                                        onChange={formikEdit.handleChange}
                                        value={formikEdit.values.firstName}
                                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" + (formikEdit.errors.firstName == 'Required' ? ' border border-red-500' : '')}
                                    />
                                </div>
                                <div className="md:col-span-12 lg:col-span-6">
                                    <div className="flex items-center justify-between">
                                        <p className="ff-cg--semibold">Last Name</p>
                                    </div>
                                    <input
                                        type="text"
                                        name="lastName"
                                        onChange={formikEdit.handleChange}
                                        value={formikEdit.values.lastName}
                                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" + (formikEdit.errors.lastName == 'Required' ? ' border border-red-500' : '')}
                                    />
                                </div>
                                <div className="md:col-span-12 lg:col-span-6">
                                    <div className="flex items-center justify-between">
                                        <p className="ff-cg--semibold">Email Address</p>
                                    </div>
                                    <input
                                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" + (formikEdit.errors.email == 'Required' ? ' border border-red-500' : '')}
                                        name="email"
                                        type="email"
                                        disabled
                                        onChange={formikEdit.handleChange}
                                        value={formikEdit.values.email}
                                    />
                                </div>
                                <div className="md:col-span-12 lg:col-span-6">
                                    <div className="flex items-center justify-between">
                                        <p className="ff-cg--semibold">Phone Number</p>
                                    </div>
                                    <MuiTelInput
                                        className={'placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium' + (formikEdit.errors.mobilePhone == 'Required' ? ' !border-solid !border !border-red-500' : '')}
                                        name="mobilePhone"
                                        defaultCountry={'US'}
                                        onChange={(value) => formikEdit.setFieldValue("mobilePhone", value)}
                                        value={formikEdit.values.mobilePhone}
                                    />
                                </div>
                            </div>
                            <div className="md:flex items-center justify-center gap-4 lg:gap-10">
                                <button onClick={() => navigate("/")}
                                        className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0">
                                    <span className="ff-cg--semibold">Return</span>
                                </button>
                                <button type="submit"
                                        className={"flex items-center justify-center bg-[#fdbf38] h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] " + (submitLoader ? '!bg-[#da1a32] justify-between' : '')}>
                                    <span className={"ff-cg--semibold " + (submitLoader ? 'text-white' : '')}>Save Change</span>
                                    <Loader open={submitLoader} dimension={"8"} min={"8"} wrapClass={''}/>
                                </button>
                            </div>
                        </form>
                    </section>
                )}

                {/* Change Password */}
                {(change) && (
                    <section className="container px-[15px] mx-auto md:mb-20 mb-10">
                        <form onSubmit={formikChange.handleSubmit}
                              className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                            <h3 className="text-[20px] md:text-[30px] mb-6">Change Password</h3>
                            <div className="grid gap-4 lg:gap-10 md:grid-cols-12 mb-10">
                                <div className="md:col-span-12 lg:col-span-6">
                                    <div className="flex items-center justify-between">
                                        <p className="ff-cg--semibold">Old Password</p>
                                    </div>
                                    <input
                                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" + (formikChange.errors.oldPassword == 'Required' ? ' border border-red-500' : '')}
                                        name="oldPassword"
                                        type="password"
                                        onChange={formikChange.handleChange}
                                        value={formikChange.values.oldPassword}
                                    />
                                    {formikChange.errors.oldPassword && (
                                        <p className='ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center'>
                                            <ExclamationTriangleIcon className='w-4 h-4 mr-1'/> Old Password is
                                            required
                                        </p>
                                    )}
                                </div>
                                <div className="md:col-span-12 lg:col-span-6">
                                    <div className="flex items-center justify-between">
                                        <p className="ff-cg--semibold">New Password</p>
                                    </div>
                                    <input
                                        className={"placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium" + (formikChange.errors.newPassword == 'Required' ? ' border border-red-500' : '')}
                                        name="newPassword"
                                        type="password"
                                        onChange={formikChange.handleChange}
                                        value={formikChange.values.newPassword}
                                    />
                                    <StrongPassword value={formikChange.values.newPassword}
                                                    validate={formikChange.errors.newPassword == 'Required' && formikChange.values.newPassword === ''}
                                                    callback={(event: boolean) => handlePassword(event)}/>
                                </div>
                            </div>
                            <div className="md:flex items-center justify-center gap-4 lg:gap-10">
                                <button onClick={() => navigate("/")}
                                        className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0">
                                    <span className="ff-cg--semibold">Return</span>
                                </button>
                                <button type="submit"
                                        className={"flex items-center justify-center bg-[#fdbf38] h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] " + (submitLoader ? '!bg-[#da1a32] justify-between' : '')}>
                                    <span className={"ff-cg--semibold " + (submitLoader ? 'text-white' : '')}>Save Change</span>
                                    <Loader open={submitLoader} dimension={"8"} min={"8"} wrapClass={''}/>
                                </button>
                            </div>
                        </form>
                    </section>
                )}

                {/* Your Applications */}
                {
                    (application) ?
                        <section className="container px-[15px] mx-auto md:mb-20 mb-10">
                            <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                                <h3 className="text-[20px] md:text-[30px] mb-6">Your Applications</h3>
                                {
                                    (!loading) ?
                                        <>
                                            {
                                                (applications.length) ?
                                                    <>
                                                        {
                                                            applications.map((item: any, index: number) => {
                                                                return (
                                                                    <Applications uuid={item.uuid}
                                                                                  description={item.course?.description}
                                                                                  key={index}/>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <div className="p-8">
                                                        <p className="font-bold text-3xl text-center w-full">No
                                                            Courses
                                                            found for this section</p>
                                                    </div>
                                            }
                                        </>
                                        :
                                        <div className="w-full h-full flex justify-center pt-20">
                                            <div className="text-center">
                                                <img src={LoaderIcon}
                                                     className={`w-[120px] h-[120px] mmb-4 inline-block`}/>
                                                <p className="font-bold text-4xl mb-2 ff-cg--semibold">Loading
                                                    Results</p>
                                                <p className="text-xl">Please wait a moment while we set things up
                                                    for
                                                    you!</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </section>
                        : ""
                }
                {/* footer */}
                <Footer/>
            </div>

        </Layout>
    )
}

export default Account;
export {Head} from "../components/Layout/Head";
